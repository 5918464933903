<template>
  <header class="border-b-sm border-blue-400 bg-blue-50">
    <div class="p-xxs max-w-content mx-auto flex h-full justify-between">
      <NuxtLink :to="{ name: 'index' }">
        <img
          class="h-full"
          src="~/assets/images/logos/reseau-agenda.svg"
          alt="Retour à l'accueil de Agenda.org, le réseau des agendas"
        />
      </NuxtLink>

      <div class="gap-xs flex items-center">
        <NuxtLink
          class="flex items-center"
          to="https://www.facebook.com/ReseauDesAgendas"
        >
          <Icon name="agenda:square-facebook" class="text-4xl" />
          <span class="sr-only">Retrouvez-nous sur Facebook</span>
        </NuxtLink>
      </div>
    </div>
  </header>
</template>
